import React, { CSSProperties } from "react";

function Loading() {
  const primaryColor = "#DE7C24"
  const container: CSSProperties = {
    width: "600px",
    height: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
  };

  const circle1: CSSProperties = {
    borderRadius: "50%",
    backgroundColor: primaryColor,
    width: "150px",
    height: "150px",
    position: "absolute",
    opacity: 0,
    animation: "scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)",
    animationDelay: '-3s'
  };
  const circle2: CSSProperties = {
    borderRadius: "50%",
    backgroundColor: primaryColor,
    width: "150px",
    height: "150px",
    position: "absolute",
    opacity: 0,
    animation: "scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)",
    animationDelay: '-2s'
  };
  const circle3: CSSProperties = {
    borderRadius: "50%",
    backgroundColor: primaryColor,
    width: "150px",
    height: "150px",
    position: "absolute",
    opacity: 0,
    animation: "scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)",
    animationDelay: '-1s'
  };
  const circle4: CSSProperties = {
    borderRadius: "50%",
    backgroundColor: primaryColor,
    width: "150px",
    height: "150px",
    position: "absolute",
    opacity: 0,
    animation: "scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32)",
    animationDelay: '0s'
  };

  return (
    <div style={container}>
      <div style={circle1}></div>
      <div style={circle2}></div>
      <div style={circle3}></div>
      <div style={circle4}></div>
      <style>
        {`@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}`}
      </style>
    </div>
  );
}

export default Loading;
