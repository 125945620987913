import React, { ChangeEvent, useContext, useEffect, useState } from "react";
//request service
//react context
import { AuthContext } from "../context/Auth.context";
import { AlertContext } from "../context/Alert.context";
import { signIn } from "../lib/service";
import Alert from "./Alert";

type NewUser = {
  email?: string;
  password?: string;
};

type User =
  | {
      cognome: string;
      created_at: string;
      email: string;
      id: number;
      id_personale: number;
      nome: string;
      ruolo: number;
    }
  | undefined;

type Props = {
  setToken: (token: string) => void;
};

function SignIn({ setToken }: Props) {
  const { addAuth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);
  const [user, setUser] = useState<NewUser>({});
  const [show, setShow] = useState<boolean>(false);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const signInHandler = async () => {
    if (user.email && user.password) {
      try {
        let res = await signIn({ email: user.email, password: user.password });
        if (res.dati) {
          addAuth!(res.dati);
          setToken(res.dati.auth.token);

          sessionStorage.setItem(
            "dati_user_a2y",
            JSON.stringify(res.dati.user)
          );
          sessionStorage.setItem(
            "token_user_a2y",
            JSON.stringify(res.dati.auth)
          );
        } else {
          throw new Error();
        }
      } catch (err: any) {
        alertMessage!(
          err.response?.data || { status: "error", info: "Errore generico" }
        );
      }
    } else {
      alertMessage!({ status: "error", info: "Inserire Email e Password" });
    }
  };

  const signInKeyPress = (event: any) => {
    event.charCode === 13 && signInHandler();
  };

  const handleShowPassword = () => {
    setShow(!show);
  };

  return (
    <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-lightGray dark:bg-slate-900 dark:text-slate-100">
      <div className="bg-white dark:bg-slate-800 dark:text-slate-400 rounded-3xl w-full md:w-[40%] px-10 py-10">
        <div className="max-w-md w-full space-y-8 mx-auto">
          <img
            className="h-8 md:h-12 mx-auto"
            src={process.env.PUBLIC_URL + "img/a2y-group-logo_oriz.png"}
            alt="App to you"
          />
          <div className="mb-4">
            <div className="relative flex justify-center text-2xl font-bold text-primary font-bold mb-5 md:mb-14">
              Accedi
            </div>
            <label
              className="block text-primary text-xl font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="input-form"
              id="email"
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleInput}
              onKeyPress={(e) => signInKeyPress(e)}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-primary text-xl font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <div className="relative mb-5">
              <input
                className="input-form"
                id="password"
                type={show ? "text" : "password"}
                placeholder="Password"
                name="password"
                onChange={handleInput}
                onKeyPress={(e) => signInKeyPress(e)}
              />
              {show ? (
                <span
                  className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                  onClick={() => handleShowPassword()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                  onClick={() => handleShowPassword()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                    />
                  </svg>
                </span>
              )}
            </div>
          </div>
          <div>
            <button
              className="w-full md:w-auto group relative mx-auto mt-20 rounded-full flex justify-center py-3 px-10 border border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 font-bold"
              onClick={() => signInHandler()}
            >
              Accedi
            </button>
          </div>
        </div>
      </div>
      <Alert />
    </div>
  );
}

export default SignIn;
