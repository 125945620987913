import React, { useContext, useEffect, useState } from "react";
//service
import { refreshAuth } from "../lib/service";
//context
import { AlertContext } from "../context/Alert.context";
import { AuthContext } from "../context/Auth.context";
//components
import Navbar from "./Navbar";
import SignIn from "./SignIn";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import NoMatchRoute from "./NoMatchRoute";
import Home from "./Home";
import UserPage from "./user-page/UserPage";
import Timesheet from "./timesheet/Timesheet";
import Alert from "./Alert";
import Report from "./report/Report";

function Main() {
  const { auth, removeAuth, addAuth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);
  const [token, setToken] = useState<string | undefined>();

  const addToken = (token: string) => {
    setToken(token);
  };

  useEffect(() => {
    let auth = JSON.parse(sessionStorage.getItem("token_user_a2y") || "{}");
    Object.keys(auth).length > 0 && setToken(auth.token);
    let user = JSON.parse(sessionStorage.getItem("dati_user_a2y") || "{}");

    Object.keys(auth).length > 0 && addAuth!({ auth: auth, user: user });

    //600000 === 10min
    let jwtExpiredCheck = setInterval(async () => {
      if (token && auth) {
        try {
          const res = await refreshAuth(auth.token);
        } catch (err) {
          removeAuth!();
          alertMessage!({ status: "error", info: "Token scaduto" });
        }
      }
    }, 600000);

    //get theme color from localstorage if exist else get the OS selected theme
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }

    return () => clearInterval(jwtExpiredCheck);
  }, [token]);

  useEffect(() => {
    !auth && setToken(undefined);
  }, [auth]);

  if (!auth?.auth?.token) {
    return <SignIn setToken={addToken} />;
  }

  return (
    <>
      <div className="h-screen w-full">
        <Navbar />
        <div className="w-full h-full pt-16 -mt-16">
          <Routes>
            <Route path="*" element={<NoMatchRoute />} />
            <Route path="/" element={<Home />} />
            <Route path="/user" element={<UserPage />} />
            <Route path="/timesheet" element={<Timesheet />} />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
        <Alert />
      </div>
    </>
  );
}

export default Main;

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();

  const role = auth?.user?.ruolo;

  if (role !== 1) return <Navigate to="/" state={{ from: location }} />;

  return children;
};
