import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/Auth.context";
import { handleApp, adminApp } from "../lib/common/handleApp";
import { App } from "../lib/model/app.model";

function Home() {
  const { auth } = useContext(AuthContext);
  const [apps, setApps] = useState<App[]>([]);
  const [query, setQuery] = useState<string>("");  

  const navigate = useNavigate();

  useEffect(() => {
    if (auth?.user!.ruolo === 1) {
      setApps([...handleApp, ...adminApp]);
    } else {
      setApps([...handleApp]);
    }
  }, []);

  const goTo = (el: any) => {
    navigate(el.url);
  };  

  const filteredApps =
    query === ""
      ? apps
      : apps.filter((app) => {
          return app.name!.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-primary">
        <div className="w-full md:w-auto md:py-10 md:px-32 rounded-3xl text-white">
          <input
            className="shadow appearance-none border dark:border-slate-900 rounded-[4px] w-full dark:bg-slate-800 py-3 px-3 text-primary leading-tight mb-14 placeholder:text-center"
            id="filterApp"
            type="text"
            placeholder="Cerca fra le app..."
            name="filterApp"
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="w-md w-full space-y-8">
            <div className="mb-4 w-full">
              <div className="grid grid-cols-4 md:gap-10 w-full">
                {filteredApps.map((app, index) => (
                  <button key={index}>
                    <div
                      className="w-full p-2 rounded-xl hover:bg-orange-600 dark:text-slate-800"
                      onClick={() => goTo(app)}
                    >
                      {app.svg}
                      <p className="m-auto"> {app.name} </p>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
