import React from "react";

function NoMatchRoute() {
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-primary">
        <div className="p-10 md:py-10 md:px-32 rounded-3xl text-white">
          <div className="max-w-md w-full space-y-8">
            No match route with {document.location.pathname}
          </div>
        </div>
      </div>
    </>
  );
}

export default NoMatchRoute;
