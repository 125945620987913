import React, {
  useContext,
  useState,
  Fragment,
  useRef,
  useEffect,
} from "react";
//service
import {
  deleteSummary,
  putSummary,
  putTask,
  retrieveProjects,
  retrieveTasks,
  retrieveUserStories,
} from "../../lib/service";
//react context
import { AuthContext } from "../../context/Auth.context";
import { AlertContext } from "../../context/Alert.context";
//model
import { Summary } from "../../lib/model/timesheet/summary.model";
import { Project } from "../../lib/model/timesheet/project.model";
import { UserStory } from "../../lib/model/timesheet/userStory.model";
import { Task } from "../../lib/model/timesheet/task.model";
//assets
import { Dialog, Transition } from "@headlessui/react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

type Props = {
  open: boolean;
  closeDrawer: () => void;
  isNew: boolean;
  day: string | undefined;
  summary: Summary | undefined;
  updateSummary: (summary: Summary[]) => void;
  summaryList: Summary[];
};

function HandleSummary(props: Props) {
  const { open, closeDrawer, isNew, day, summary, updateSummary, summaryList } =
    props;
  const { auth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [handleSummary, setHandleSummary] = useState<Summary | undefined>();

  const [projects, setProjects] = useState<Project[]>([]);
  const [userStories, setUserStories] = useState<UserStory[] | undefined>([]);
  const [storyRef, setStoryRef] = useState<any>();
  const [tasks, setTasks] = useState<Task[] | undefined>([]);
  const [taskRef, setTaskRef] = useState<any>();

  const token = auth?.auth!.token!;
  const cancelButtonRef = useRef(null);

  const handleModal = () => {
    setOpenModal(true);
  };
  

  const closeModal = () => {
    setOpenModal(false);
  };
  

  useEffect(() => {
    if (open) {
      if (summary) {
        setHandleSummary(summary);
      } else {
        setHandleSummary({ giorno: new Date(day!).toISOString() });
      }

      const fetchData = async () => {
        if (summary) {
          const projectsRes = await retrieveProjects(token);
          if (projectsRes.dati) setProjects(projectsRes.dati);
          else throw new Error();
          const storiesRes = await retrieveUserStories(
            token,
            summary.id_progetto!
          );
          if (storiesRes.dati) setUserStories(storiesRes.dati);
          else throw new Error();
          const tasksRes = await retrieveTasks(
            token,
            summary.id_staffing_attivita!
          );
          if (tasksRes.dati) setTasks(tasksRes.dati);
          else throw new Error();
        } else {
          const res = await retrieveProjects(token);
          if (res.dati) setProjects(res.dati);
          else throw new Error();
        }
      };

      fetchData().catch(() =>
        alertMessage!({
          status: "error",
          info: "Errore generico.",
        })
      );
    }

    return () => {
      setHandleSummary(undefined);
      setProjects([]);
      setUserStories([]);
      setTasks([]);
    };
  }, [open]);

  const handleAutocomplete = async (event: any, name: string) => {
    if (name === "progetto") {
      setHandleSummary({
        ...handleSummary,
        id_progetto: event.id,
        progetto: { id: event.id, nome: event.nome },
        id_staffing_attivita: undefined,
        id_staffing_mansione: undefined,
      });
      setUserStories(undefined);
      storyRef.clearValue();
      taskRef.clearValue();

      try {
        const res = await retrieveUserStories(token, event.id);
        if (res.dati) {
          setUserStories(res.dati);
        }
      } catch (error) {
        alertMessage!({
          status: "error",
          info: "Errore nel recupero delle user stories.",
        });
      }
    }

    if (name === "attività" && event) {
      setHandleSummary({
        ...handleSummary,
        id_staffing_attivita: event.id,
        attivita: { id: event.id, nome: event.nome },
        id_staffing_mansione: undefined,
      });
      setTasks(undefined);
      taskRef.clearValue();

      try {
        const res = await retrieveTasks(token, event.id);
        if (res.dati) {
          setTasks(res.dati);
        }
      } catch (err) {
        alertMessage!({
          status: "error",
          info: "Errore nel recupero delle tasks.",
        });
      }
    }

    if (name === "mansione" && event) {
      setHandleSummary({
        ...handleSummary,
        id_staffing_mansione: event.id,
        mansione: {
          id: event.id,
          nome: event.nome || event.label,
          __isNew__: event?.__isNew__,
        },
      });
    }
  };

  const createUpdateSummary = async () => {
    //check if all field are valid id_progetto, id_staffing_attivita, id_staffing_mansione, tempo effettivo
    if(!handleSummary?.id_progetto || !handleSummary?.id_staffing_attivita || !handleSummary?.mansione || !handleSummary?.tempo_effettivo) {
      alertMessage!({
        status: "error",
        info: "Compilare tutti i campi.",
      });
     return
    };

    let sendSummary: Summary = {
      id: handleSummary?.id,
      conclusa: handleSummary?.conclusa || 0,
      concordato: handleSummary?.concordato || 0,
      giorno: handleSummary!.giorno,
      git: handleSummary?.git || 0,
      id_progetto: handleSummary!.id_progetto!.toString(),
      id_staffing_attivita: handleSummary?.id_staffing_attivita!.toString(),
      id_staffing_mansione: handleSummary!.id_staffing_mansione?.toString(),
      note: handleSummary?.note,
      tempo_effettivo: handleSummary!.tempo_effettivo!,
    };

    //check if the task already exist, if exist take the task else make an API call for put the task then edit the summary
    if (handleSummary?.mansione?.__isNew__) {
      try {
        const res = await putTask(token, handleSummary.id_staffing_attivita!, {
          nome: handleSummary.mansione.nome!,
        });
        sendSummary = {
          ...sendSummary,
          id_staffing_mansione: res.dati.id.toString(),
        };
      } catch (err) {
        alertMessage!({
          status: "error",
          info: "Errore nel creazione del task.",
        });
      }
    }

    //API call for create/update summary
    try {
      const res = await putSummary(token, sendSummary);

      if (res.dati) {        
        if (!handleSummary?.id) {
          updateSummary([
            ...summaryList,
            {
              ...handleSummary,
              id_staffing_mansione: sendSummary.id_staffing_mansione,
              mansione: sendSummary.mansione || handleSummary.mansione,
            },
          ]);
        } else {
          let index = summaryList.findIndex(
            (el) => el.id === handleSummary?.id
          );
          updateSummary([
            ...summaryList.slice(0, index),
            {
              ...handleSummary,
              id_staffing_mansione: sendSummary.id_staffing_mansione,
            },
            ...summaryList.slice(index + 1),
          ]);
        }
      } else throw new Error();
      closeDrawer();
    } catch (err) {
      alertMessage!({
        status: "error",
        info: "Errore nel creazione del timesheet.",
      });
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-[150] right-0 top-0 w-full md:w-5/12"
        initialFocus={cancelButtonRef}
        onClose={() => {
          closeDrawer();
        }}
      >
        <div className="flex items-end justify-center h-screen w-full">
          <Transition.Child
            as={Fragment}
            enter="translate-x-0 duration-300"
            enterFrom="translate-x-full opacity-0"
            enterTo="opacity-100"
            leave="translate-x-full duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <Dialog.Overlay className="fixed inset-0 bg-mediumGray bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="translate-x-0 duration-300"
            enterFrom="translate-x-full opacity-0"
            enterTo="opacity-100"
            leave="translate-x-full duration-200"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 translate-x-full"
          >
            <div className="md:px-0 h-full bg-white dark:bg-slate-800 dark:text-slate-400 flex flex-col justify-between w-full z-[150]">
              <div className=" bg-white dark:bg-slate-800 dark:text-slate-400 py-5 drop-shadow-md px-10">
                <div className="flex justify-between">
                  <div className="flex items-center text-primary">
                    <p className="text-[17px] font-bold">
                      {isNew ? "Aggiungi Task" : "Modifica Task"}
                    </p>
                  </div>
                  {/* button close the drawer */}
                  <button
                    type="button"
                    className="flex justify-center items-center rounded-full border border-gray-300 shadow-sm px-4 bg-white dark:bg-slate-800 dark:text-slate-400 text-base font-medium text-gray-700 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => closeDrawer()}
                    ref={cancelButtonRef}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <div className="w-full h-full overflow-y-scroll">
                <div className="mt-3 text-center sm:mt-0 sm:text-left">
                  {/* main content */}
                  <div className="mt-3 sm:mt-0 text-left">
                    <div className="mt-2 w-full px-10 m-auto">
                      <label className="block text-primary text-[18px] font-bold mb-[8px]">
                        Progetti
                      </label>
                      <Select
                        className="basic-single mb-[20px]"
                        classNamePrefix="select"
                        isSearchable={true}
                        isLoading={projects.length === 0}
                        placeholder="Progetti"
                        defaultValue={
                          handleSummary &&
                          handleSummary.progetto && {
                            ...handleSummary.progetto,
                            value: handleSummary.progetto.id,
                            label:
                              `${handleSummary.progetto.cliente?.nome} - ${handleSummary.progetto.nome}` ||
                              undefined,
                          }
                        }
                        options={projects.map((el) => ({
                          ...el,
                          value: el.id,
                          label: el.nome,
                        }))}
                        onChange={(event) =>
                          handleAutocomplete(event, "progetto")
                        }
                      />
                      <label className="block text-primary text-xl font-bold mb-[8px]">
                        User Story
                      </label>
                      <Select
                        className="basic-single mb-[20px]"
                        classNamePrefix="select"
                        isSearchable={true}
                        ref={(ref) => setStoryRef(ref)}
                        isDisabled={!handleSummary?.id_progetto}
                        placeholder="User Story"
                        isLoading={!userStories}
                        defaultValue={
                          handleSummary &&
                          handleSummary.attivita && {
                            ...handleSummary.attivita,
                            value: handleSummary.attivita.id,
                            label: handleSummary.attivita.nome,
                          }
                        }
                        options={userStories?.map((el) => ({
                          ...el,
                          value: el.id,
                          label: el.nome,
                        }))}
                        onChange={(event) =>
                          handleAutocomplete(event, "attività")
                        }
                      />
                      <label className="block text-primary text-xl font-bold mb-[8px]">
                        Task
                      </label>
                      <CreatableSelect
                        className="basic-single mb-[20px]"
                        classNamePrefix="select"
                        ref={(ref) => setTaskRef(ref)}
                        isSearchable={true}
                        name={"id_staffing_mansione"}
                        placeholder="task"
                        isDisabled={!handleSummary?.id_staffing_attivita}
                        defaultValue={
                          handleSummary &&
                          handleSummary.mansione && {
                            ...handleSummary.mansione,
                            value: handleSummary.mansione.id,
                            label: handleSummary.mansione.nome,
                          }
                        }
                        options={tasks?.map((el) => ({
                          ...el,
                          value: el.id,
                          label: el.nome,
                        }))}
                        onChange={(event) =>
                          handleAutocomplete(event, "mansione")
                        }
                      />
                      <div>
                        <label className="block text-primary text-xl font-bold mb-[8px]">
                          Ore
                        </label>
                        <input
                          type="number"
                          name="tempo_effettivo"
                          className="input-form mb-[20px]"
                          placeholder="0,00"
                          defaultValue={handleSummary?.tempo_effettivo}
                          onChange={(e) =>
                            setHandleSummary({
                              ...handleSummary,
                              tempo_effettivo: e.target.value.toString(),
                            })
                          }
                        />
                      </div>
                      <div>
                        <label className="block text-primary text-xl font-bold mb-[8px]">
                          Descrizione/Note
                        </label>
                        <textarea
                          className="input-form resize-none mb-[20px]"
                          placeholder="Note..."
                          name="note"
                          defaultValue={handleSummary?.note || undefined}
                          onChange={(e) =>
                            setHandleSummary({
                              ...handleSummary,
                              note: e.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                      <div className="flex flex-col gap-2">
                        <label className="block text-primary text-xl font-bold mb-[8px]">
                          Stato del task
                        </label>
                        <div className="flex items-center gap-[20px]">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="concordato"
                              defaultChecked={
                                handleSummary?.concordato === 1 ? true : false
                              }
                              onChange={(e) =>
                                setHandleSummary({
                                  ...handleSummary,
                                  concordato: e.target.checked === true ? 1 : 0,
                                })
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <label className="block text-primary text-[16px] font-bold">
                            Concordato
                          </label>
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="git"
                              defaultChecked={
                                handleSummary?.git === 1 ? true : false
                              }
                              onChange={(e) =>
                                setHandleSummary({
                                  ...handleSummary,
                                  git: e.target.checked === true ? 1 : 0,
                                })
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <label className="block text-primary text-[16px] font-bold">
                            Da remoto / In presenza
                          </label>
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="conclusa"
                              defaultChecked={
                                handleSummary?.conclusa === 1 ? true : false
                              }
                              onChange={(e) =>
                                setHandleSummary({
                                  ...handleSummary,
                                  conclusa: e.target.checked === true ? 1 : 0,
                                })
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                          <label className="block text-primary text-[16px] font-bold">
                            Conclusa
                          </label>
                        </div>
                      </div>
                      <div>
                        {/* delete button open a modal to complete the delete process*/}
                        {!isNew && (
                          <HandleDeleteSummary
                            summary={summary!}
                            openModal={openModal}
                            handleModal={handleModal}
                            closeModal={closeModal}
                            closeDrawer={closeDrawer}
                            updateSummary={updateSummary}
                            summaryList={summaryList}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-white dark:bg-slate-800 dark:text-slate-400 py-5 shadow-[0_-10px_15px_-3px_rgba(0,0,0,0.1)] px-10">
                {/* edit button make an API call for edit the summary */}
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-full border border-primary shadow-sm px-8 py-2 bg-primary text-base font-medium text-white dark:text-slate-800 sm:w-auto sm:text-sm mr-4"
                  onClick={() => createUpdateSummary()}
                >
                  Salva
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-full border border-primary shadow-sm px-6 py-2 text-primary font-medium sm:w-auto sm:text-sm mr-4"
                  onClick={() => closeDrawer()}
                >
                  Annulla
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default HandleSummary;

type DeleteProps = {
  summary: Summary;
  openModal: boolean;
  handleModal: () => void;
  closeModal: () => void;
  closeDrawer: () => void;
  updateSummary: (summary: Summary[]) => void;
  summaryList: Summary[];
};

function HandleDeleteSummary(props: DeleteProps) {
  const {
    summary,
    openModal,
    handleModal,
    closeModal,
    closeDrawer,
    updateSummary,
    summaryList,
  } = props;
  const { auth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);

  const token = auth?.auth!.token!;
  const cancelButtonRef = useRef(null);

  //API call for delete the task
  let deleteTask = async (id: number) => {
    try {
      const res = await deleteSummary(token, id);
      if (res) {
        let index = summaryList.findIndex((el) => el.id === id);
        updateSummary([
          ...summaryList.slice(0, index),
          ...summaryList.slice(index + 1),
        ]);
        closeModal();
        closeDrawer();
        alertMessage!({ status: "confirm", info: "Eliminato" });
      } else throw new Error();
    } catch (e) {
      alertMessage!({ status: "error", info: "Errore generico" });
    }
  };

  return (
    <>
      <button
        type="button"
        className="w-full inline-flex justify-center text-red-500 sm:w-auto sm:text-sm text-[16px] font-bold my-[80px]"
        onClick={() => handleModal()}
      >
        Vuoi eliminare questo task?
      </button>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-[200] inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={() => closeModal()}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-mediumGray bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white dark:bg-slate-800 dark:text-slate-400 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full py-4">
                <div className="bg-white dark:bg-slate-800 dark:text-slate-400 px-4 sm:p-6 sm:pb-4">
                  <div className="w-full">
                    <div className="text-center sm:ml-4 sm:text-left">
                      <div className="grid gap-4 w-10/12 m-auto text-primary font-bold mb-6">
                        <p>Sei sicuro di voler eliminare questo task?</p>
                      </div>
                      <div className="flex flex-col gap-2">
                        <p>
                          {summary?.progetto?.cliente?.nome} -{" "}
                          {summary?.progetto?.nome}
                        </p>
                        <p>{summary?.attivita?.nome}</p>
                        <p>{summary?.mansione?.nome}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 sm:px-6 sm:flex sm:flex gap-4">
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-full bg-primary shadow-sm px-8 py-2 text-white font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm md:w-full"
                    onClick={() => deleteTask(summary.id!)}
                    ref={cancelButtonRef}
                  >
                    Elimina
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-full border border-primary shadow-sm px-6 py-2 text-primary font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm md:w-full"
                    onClick={() => closeModal()}
                    ref={cancelButtonRef}
                  >
                    Annulla
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
