import React, { Fragment, useContext, useEffect, useState } from "react";
//assets
import { Transition } from "@headlessui/react";
import { AlertContext } from "../context/Alert.context";

function Alert() {
  const { alert, alertMessage } = useContext(AlertContext);
  const [open, setOpen] = useState<boolean>(false);
  const [completion, setCompletion] = useState<number>(0);  
  
  useEffect(() => {
    if (alert?.hasOwnProperty("status") || alert?.info) {
      setOpen(true);
      setTimeout(() => {
        alertMessage!({});
      }, 5000);
    } else {
      setOpen(false);
    }
  }, [alert]);

  return (
    <>
      <Transition
        show={open}
        as={Fragment}
        enter="translate-x-0 duration-300"
        enterFrom="translate-x-full opacity-0"
        enterTo="opacity-100"
        leave="translate-x-full duration-300"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 translate-x-full"
      >
        <div
          className={`${
            alert?.status === "success"
              ? "bg-green-500 text-[#fff]"
              : "bg-red-500 text-[#fff]"
          } px-4 py-3 rounded absolute bottom-0 right-0 mr-6 mb-6 z-[999]`}
          role="alert"
        >
          <span className="block sm:inline mr-12">{alert?.info}</span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3 cursor-pointer"
            onClick={() => setOpen(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </span>
          <div className="absolute bottom-0 left-0 bg-red-700 h-1" style={{width: `${completion}%`}}></div>
        </div>
      </Transition>
    </>
  );
}
export default Alert;
