import React, { useContext, useEffect, useState } from "react";
//service
import { retrieveDays } from "../../lib/service";
//context
import { AuthContext } from "../../context/Auth.context";
import { AlertContext } from "../../context/Alert.context";
//model
import { Date } from "../../lib/model/timesheet/date.model";
import { Summary } from "../../lib/model/timesheet/summary.model";
//components
import SummaryComponent from "./Summary";
import Loading from "../Loading";
import HandleSummary from "./HandleSummary";

function Timesheet() {
  const { auth, removeAuth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);
  //API state
  const [date, setDate] = useState<Date>({});
  //end API state
  const [loading, setLoading] = useState<boolean>(true);

  const token = auth?.auth?.token!;

  useEffect(() => {
    setLoading(true);
    const fetchDays = async () => {
      const res = await retrieveDays(token);
      if (res.dati) setDate(res.dati);
      else throw new Error();
    };

    fetchDays().catch((err) => {
      if (err?.response?.data?.info === "ExpiredJwtToken") {
        removeAuth!();
      } else {
        alertMessage!({ status: "error", info: "Errore generico" });
      }
    });
    setLoading(false);
  }, []);

  //get previous week
  let loadPrevWeek = async () => {
    let prevWeek = date.weeks!.prev.week;
    let prevYear = date.weeks!.prev.year;
    try {
      const res = await retrieveDays(token, prevWeek, prevYear);
      if (res.dati) setDate(res.dati);
      else throw new Error();
    } catch (err) {
      alertMessage!({ status: "error", info: "Errore generico" });
    }
  };

  //get next week
  let loadNextWeek = async () => {
    let nextWeek = date.weeks!.next.week;
    let nextYear = date.weeks!.next.year;
    try {
      const res = await retrieveDays(token, nextWeek, nextYear);
      if (res.dati) setDate(res.dati);
      else throw new Error();
    } catch (err) {
      alertMessage!({ status: "error", info: "Errore generico" });
    }
  };
  return (
    <>
      <div className="flex bg-lightGray dark:bg-slate-900 dark:text-slate-100 h-full">
        <div className="w-full overflow-x-auto px-5">
          <div className="h-full">
            <div className="md:px-0 rounded-t-xl flex flex-col justify-between h-full">
              <div className="h-full flex flex-col justify-between bg-lightGray dark:bg-slate-900 dark:text-slate-100">
                <div className="py-8">
                  {/*buttons for navigate through the weeks*/}
                  {date.days && (
                    <div className="flex justify-center gap-12">
                      {date.weeks!.prev.week !== null &&
                      date.weeks!.prev.year !== null ? (
                        <button
                          className="hover:bg-primary hover:text-white hover:rounded-full"
                          onClick={() => loadPrevWeek()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </button>
                      ) : (
                        <div className="text-mediumGray flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15 19l-7-7 7-7"
                            />
                          </svg>
                        </div>
                      )}
                      <div className="flex text-center">
                        <p className="first-letter:uppercase">
                          {date.days[0].name}
                        </p>
                        <span className="mx-2">-</span>
                        <p className="first-letter:uppercase">
                          {date.days[date.days.length - 1].name}
                        </p>
                      </div>
                      {date.weeks!.next.week !== null &&
                      date.weeks!.next.year !== null ? (
                        <button
                          className="hover:bg-primary hover:text-white hover:rounded-full"
                          onClick={() => loadNextWeek()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </button>
                      ) : (
                        <div className="text-mediumGray flex items-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 5l7 7-7 7"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="w-full h-full overflow-y-scroll">
                  <div>
                    {loading && (
                      <div className="text-center h-full w-full flex items-center justify-center mt-16">
                        <Loading />
                      </div>
                    )}
                    {
                      //summary card
                      date?.days?.map((day, index) => (
                        <div
                          key={index}
                          className={`mb-8 ${loading ? "hidden" : ""}`}
                        >
                          <SummaryComponent
                            day={day}
                          />
                        </div>
                      ))
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Timesheet;
