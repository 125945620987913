import React, {
  ChangeEvent,
  Fragment,
  useContext,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
//service
import { changePassword as handleChangePassword } from "../../lib/service";
//react context
import { AuthContext } from "../../context/Auth.context";
import { AlertContext } from "../../context/Alert.context";
//assets
import { Dialog, Transition } from "@headlessui/react";

type ChangePassword = {
  vecchia_password?: string;
  nuova_password?: string;
  conferma_password?: string;
};

function UserPage() {
  const { auth, removeAuth } = useContext(AuthContext);
  const { alertMessage } = useContext(AlertContext);
  const [open, setOpen] = useState(false);
  const [changePassword, setChangePassword] = useState<ChangePassword>({});
  const [showOldPass, setShowOldPass] = useState<boolean>(false);
  const [showNewPass, setShowNewPass] = useState<boolean>(false);
  const [showConfirmPass, setShowConfirmPass] = useState<boolean>(false);

  const navigate = useNavigate();
  const token = auth!.auth!.token!;

  const cancelButtonRef = useRef(null);

  let handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setChangePassword({
      ...changePassword,
      [event.target.name]: event.target.value,
    });
  };

  let sendPassword = async () => {
    if (changePassword.nuova_password === changePassword.conferma_password) {
      try {
        const res = await handleChangePassword(token, {
          nuova_password: changePassword.nuova_password!,
          vecchia_password: changePassword.vecchia_password!,
        });
        if (res) {
          //log out after change password
          removeAuth!();
          sessionStorage.removeItem("dati_user_a2y");
          sessionStorage.removeItem("token_user_a2y");
          navigate("/");
          document.location.reload();

          alertMessage!({ status: "succes", info: "password modificata" });
        } else {
          throw new Error();
        }
      } catch (err) {
        alertMessage!({ status: "error", info: "Errore generico" });
      }
    } else {
      alertMessage!({ status: "error", info: "Le password non corrispondono" });
    }
  };

  return (
    <>
      <div className="flex bg-lightGray dark:bg-slate-900 dark:text-slate-100 h-full">
        <div className="w-full overflow-x-auto px-5">
          <div className="h-full pt-10">
            <div className="bg-white dark:bg-slate-800 dark:text-slate-400 rounded-t-xl flex flex-col justify-between h-full">
              <div className="px-2 md:px-0">
                <div className="p-2 md:p-10 divide-y divide-lightGray">
                  <div className="py-2 my-3 grid grid-cols-7">
                    <div className="text-primary text-xl">Nome</div>
                    <div className="mx-10 text-xl col-span-6">
                      {auth!.user!.nome}
                    </div>
                  </div>
                  <div className="py-2 my-3 grid grid-cols-7">
                    <div className="text-primary text-xl">Cognome</div>
                    <div className="mx-10 text-xl col-span-6">
                      {auth!.user!.cognome}
                    </div>
                  </div>
                  <div className="py-2 my-3 grid grid-cols-7">
                    <div className="text-primary text-xl">Email Aziendale</div>
                    <div className="mx-10 text-xl col-span-6">
                      {auth!.user!.email}
                    </div>
                  </div>
                  <div className="py-2 my-3 grid grid-cols-7">
                    <div className="text-primary text-xl">Password</div>
                    <div className="mx-10 text-xl col-span-6">
                      <button
                        type="button"
                        className="py-[6px] px-[10px] rounded-[4px] bg-primary text-white"
                        onClick={() => setOpen(true)}
                      >
                        Modifica password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <Transition.Root show={open} as={Fragment}>
                <Dialog
                  as="div"
                  className="fixed z-[150] right-0 top-0 w-full md:w-5/12"
                  initialFocus={cancelButtonRef}
                  onClose={setOpen}
                >
                  <div className="flex items-end justify-center h-screen w-full">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Dialog.Overlay className="fixed inset-0 bg-mediumGray bg-opacity-75 transition-opacity" />
                    </Transition.Child>
                    <span
                      className="hidden sm:inline-block sm:align-middle sm:h-screen"
                      aria-hidden="true"
                    >
                      &#8203;
                    </span>
                    <Transition.Child
                      as={Fragment}
                      enter="translate-x-0 duration-300"
                      enterFrom="translate-x-full opacity-0"
                      enterTo="opacity-100"
                      leave="translate-x-full duration-200"
                      leaveFrom="opacity-100 translate-x-0"
                      leaveTo="opacity-0 translate-x-full"
                    >
                      <div className="h-full bg-white dark:bg-slate-800 dark:text-slate-400 flex flex-col justify-between w-full z-[150]">
                        <div className="bg-white dark:bg-slate-800 dark:text-slate-400 py-5 drop-shadow-md p-4">
                          <div className="flex justify-between">
                            <div className="flex items-center text-primary">
                              <p>Modifica password</p>
                            </div>
                            {/* button close the drawer */}
                            <button
                              type="button"
                              className="flex justify-center items-center rounded-full border border-gray-300 shadow-sm bg-white dark:bg-slate-800 dark:text-slate-400 text-base font-medium text-gray-700 sm:mt-0 sm:w-auto sm:text-sm"
                              onClick={() => setOpen(false)}
                              ref={cancelButtonRef}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="bg-white dark:bg-slate-800 dark:text-slate-400 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                          <div className="w-full">
                            <div className="text-center sm:mt-0 sm:text-left">
                              <div className="mt-2 w-full m-auto">
                                <div className="mb-8">
                                  <label className="block text-primary text-xl font-bold">
                                    Vecchia password
                                  </label>
                                  <div className="relative">
                                    <input
                                      type={showOldPass ? "text" : "password"}
                                      className="input-form"
                                      name="vecchia_password"
                                      onChange={handleInput}
                                    />
                                    {showOldPass ? (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() => setShowOldPass(false)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                          />
                                        </svg>
                                      </span>
                                    ) : (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() => setShowOldPass(true)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="mb-8">
                                  <label className="block text-primary text-xl font-bold">
                                    Nuova password
                                  </label>
                                  <div className="relative">
                                    <input
                                      type={showNewPass ? "text" : "password"}
                                      className="input-form"
                                      name="nuova_password"
                                      onChange={handleInput}
                                    />
                                    {showNewPass ? (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() => setShowNewPass(false)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                          />
                                        </svg>
                                      </span>
                                    ) : (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() => setShowNewPass(true)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                </div>
                                <div className="mb-8">
                                  <label className="block text-primary text-xl font-bold">
                                    Conferma password
                                  </label>
                                  <div className="relative">
                                    <input
                                      type={
                                        showConfirmPass ? "text" : "password"
                                      }
                                      className="input-form"
                                      name="conferma_password"
                                      onChange={handleInput}
                                    />
                                    {showConfirmPass ? (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() =>
                                          setShowConfirmPass(false)
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                                          />
                                        </svg>
                                      </span>
                                    ) : (
                                      <span
                                        className="absolute inset-y-0 right-0 flex items-center pr-4 text-mediumGray"
                                        onClick={() => setShowConfirmPass(true)}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-5 w-5"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                          strokeWidth="2"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                          <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                          />
                                        </svg>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" bg-white dark:bg-slate-800 dark:text-slate-400 py-5 shadow-[0_-10px_15px_-3px_rgba(0,0,0,0.1)] px-10">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-full border border-transparent shadow-sm px-4 py-2 bg-green text-base font-medium text-white sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={() => sendPassword()}
                          >
                            Salva
                          </button>
                        </div>
                      </div>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserPage;
