import { createContext, ReactNode, useState } from "react";
import { Auth } from "../lib/model/auth.model";

type AuthContextType = {
  auth: Auth;
  addAuth: (auth: Auth) => void;
  removeAuth: () => void;
};

export const AuthContext = createContext<Partial<AuthContextType>>({});

type Props = {
  children: ReactNode;
};

const AuthProvider = ({ children }: Props) => {
  const [auth, setAuth] = useState<Auth>({});

  const addAuth = (auth: Auth) => {
    setAuth(auth);
  };

  const removeAuth = () => {
    setAuth({});
  };

  const value = {
    auth,
    addAuth,
    removeAuth,
  };

  return (
    <>
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </>
  );
};

export default AuthProvider;
