import React from 'react';
import "./App.css";
import Main from "./components/Main";
import AlertProvider from './context/Alert.context';
import AuthProvider from './context/Auth.context';

function App() {
  return (
    <>
      <AuthProvider>
        <AlertProvider>
          <Main />
        </AlertProvider>
      </AuthProvider>
    </>
  );
}

export default App;
