import axios from "axios";
import { Summary } from "./model/timesheet/summary.model";

const BASE_URL = process.env.REACT_APP_ENDPOINT_URL;

/* ----------------------------------------------------------------
 * Auth service
 */
export const signIn = async (user: { email: string; password: string }) => {
  const { data } = await axios.post(`${BASE_URL}/auth/login`, user);
  return data;
};

export const refreshAuth = async (token: string) => {
  const { data } = await axios.get(`${BASE_URL}/user?token=${token}`);
  return data;
};

/* ----------------------------------------------------------------
 * User service
 */

export const changePassword = async (token: string, newPassword: {nuova_password: string, vecchia_password: string}) => {
  const { data } = await axios.post(`${BASE_URL}/user/cambio_password?token=${token}`, newPassword)
  return data;
}

/* ----------------------------------------------------------------
 * Timesheet service
 */

export const retrieveDays = async (
  token: string,
  week?: number,
  year?: number
) => {
  const { data } = await axios.get(
    `${BASE_URL}/riepilogo/days?token=${token}${
      week && year ? `&week=${week}&year=${year}` : ""
    }`
  );
  return data;
};

export const retrieveSummary = async (token: string, timestamp: string) => {
  const { data } = await axios.get(
    `${BASE_URL}/riepilogo?token=${token}&giorno=${timestamp}`
  );
  return data;
};

export const retrieveProjects = async (token: string) => {
  const { data } = await axios.get(
    `${BASE_URL}/riepilogo/progetti?token=${token}`
  );
  return data;
};

export const retrieveUserStories = async (token: string, idProgetto: string) => {
  const { data } = await axios.get(`${BASE_URL}/riepilogo/user-stories?token=${token}&progetto=${idProgetto}`);
  return data;
}

export const retrieveTasks = async (token: string, storyId: string) => {
  const { data  } = await axios.get(`${BASE_URL}/user_stories/${storyId}/tasks?token=${token}`);
  return data;
}

export const putTask = async (token: string, storyId: string, task: {nome: string}) => {
  const { data } = await axios.put(`${BASE_URL}/user_stories/${storyId}/tasks?token=${token}`, task);
  return data;
}

export const putSummary  = async (token: string, summary: Summary) => {
  const { data } = await axios.put(`${BASE_URL}/riepilogo?token=${token}`, summary);
  return data;
}

export const deleteSummary = async (token: string, id: number) => {
  const { data } = await axios.delete(`${BASE_URL}/riepilogo/${id}?token=${token}`);
  return data
}
