import { createContext, ReactNode, useState } from "react";
import { Alert } from "../lib/model/alert.model";

type AlertContextType = {
  alert: Alert;
  alertMessage: (alert: Alert) => void;
};

export const AlertContext = createContext<Partial<AlertContextType>>({});

type Props = {
    children: ReactNode;
};

const AlertProvider= ({ children }: Props) => {
  const [alert, setAlert] = useState<Alert>({});

  const alertMessage = (alert: Alert) => {
    setAlert(alert);
  };

  return (
    <AlertContext.Provider value={{ alert, alertMessage }}>
      {children}
    </AlertContext.Provider>
  );
};

export default AlertProvider;
