import React, { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
//model
import { App } from "../lib/model/app.model";
//raect context
import { AuthContext } from "../context/Auth.context";
//assets
import { handleApp, adminApp } from "../lib/common/handleApp";
import { Menu, Transition } from "@headlessui/react";

function Navbar() {
    const { auth, removeAuth } = useContext(AuthContext);
    const [activeApp, setActiveApp] = useState<App>();
    const [apps, setApps] = useState<App[]>([]);
    const [darkMode, setDarkMode] = useState<boolean>(false);

    const location: string = `/${document.location.pathname.split("/")[1]}`;

    const navigate = useNavigate();

    useEffect(() => {
        if (auth?.user?.ruolo === 1) {
          setApps([...handleApp, ...adminApp]);
        } else {
          setApps([...handleApp]);
        }
      }, [auth]);

    useEffect(() => {
        //get theme color from localstorage if exist else get the OS selected theme
        if (
          localStorage.theme === "dark" ||
          (!("theme" in localStorage) &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
        ) {
          document.documentElement.classList.add("dark");
          document.documentElement.setAttribute("theme", "dark");
          setDarkMode(true);
        } else {
          document.documentElement.classList.remove("dark");
          document.documentElement.removeAttribute("dark");
          setDarkMode(false);
        }
      }, [darkMode]);

      useEffect(() => {
        let activeApp: App[] = [];
        apps.forEach((app) => {
          if (app.url === location) {
            activeApp.push(app);
          }
        });
        setActiveApp(activeApp[0]);
      }, [apps, location]);
    
      let logOut = () => {
        removeAuth!();
        sessionStorage.removeItem("dati_user_a2y");
        sessionStorage.removeItem("token_user_a2y");
        navigate("/");
      };
    
      const goTo = (app: App) => {
          app.url && navigate(app.url);
      };
    
      const handleSwitch = (e: any) => {
        e.target.checked === false
          ? (localStorage.theme = "light")
          : (localStorage.theme = "dark");
    
        setDarkMode(localStorage.theme);
      };

  return (
    <nav className="bg-white dark:bg-slate-800 dark:text-slate-400 relative w-full drop-shadow-md z-[100]">
      <div className="h-16 w-11/12 m-auto flex justify-between items-center">
        <div className={!auth?.auth ? "hidden" : "block"}>
          <div className="flex">
            {/* app menu */}
            <Menu>
              <Menu.Button className="rounded-full p-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-lightGray hover:bg-opacity-30 dark:hover:bg-slate-900 dark:text-slate-100">
                <svg
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 6.5H6.5V0H0V6.5ZM9.75 26H16.25V19.5H9.75V26ZM0 26H6.5V19.5H0V26ZM0 16.25H6.5V9.75H0V16.25ZM9.75 16.25H16.25V9.75H9.75V16.25ZM19.5 0V6.5H26V0H19.5ZM9.75 6.5H16.25V0H9.75V6.5ZM19.5 16.25H26V9.75H19.5V16.25ZM19.5 26H26V19.5H19.5V26Z"
                    fill="#9F9F9F"
                  />
                </svg>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="origin-top-left ml-2 absolute mt-8 z-10">
                  <Menu.Items>
                    <div className="w-16 overflow-hidden inline-block ml-0.5 drop-shadow-xl">
                      <div className=" h-4 w-4 bg-primary rotate-45 transform origin-bottom-left"></div>
                    </div>
                    <div className="bg-primary -mt-2 p-4 -ml-3 rounded-md text-white text-center drop-shadow-xl relative">
                      <div className="grid grid-cols-3 gap-5 relative">
                        {apps.map((app, index) => (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <button key={index}>
                                <div
                                  className="w-full p-2 rounded-xl hover:bg-orange-600 dark:text-slate-800"
                                  onClick={() => goTo(app)}
                                >
                                  {app.svg}
                                  <p className="m-auto"> {app.name} </p>
                                </div>
                              </button>
                            )}
                          </Menu.Item>
                        ))}
                      </div>
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>
            <div className="flex items-center text-primary ml-3">
              {activeApp?.svg}
              <p className="ml-1 text-xl">{activeApp?.name}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-center">
          {/* avatar menu */}
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center w-full p-2 rounded-full text-mediumGray rounded-full bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 hover:bg-lightGray dark:hover:bg-slate-900 dark:text-slate-100">
                {auth?.user?.hasOwnProperty("nome") &&
                auth?.user?.hasOwnProperty("cognome") ? (
                  <div className="text-primary border border-primary px-1 py-0.5 font-semibold text-xl rounded-full">
                    {auth?.user?.nome[0] + auth?.user?.cognome[0]}
                  </div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-8 w-8 mx-auto"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )}
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 w-56 origin-top-right bg-white dark:bg-slate-800 dark:text-slate-400 divide-y divide-gray-100 rounded-md shadow-lg">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className="group flex rounded-md items-center w-full px-2 py-2 text-primary hover:text-white hover:bg-primary dark:hover:text-slate-800"
                        onClick={() => navigate("/user")}
                      >
                        Il mio profilo
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        className="group flex rounded-md items-center w-full px-2 py-2 text-primary hover:text-white hover:bg-primary dark:hover:text-slate-800"
                        onClick={() => logOut()}
                      >
                        Log out
                      </button>
                    )}
                  </Menu.Item>
                  <div className="flex py-2 px-2 items-center border-t border-lightGray dark:border-slate-900">
                    <label className="block text-primary mr-4">Dark mode</label>
                    <label className="switch">
                      <input
                        type="checkbox"
                        name="dark-mode"
                        checked={darkMode}
                        onChange={handleSwitch}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
          <p className="text-mediumGray text-xl">|</p>
          <img
            className="h-16 mx-auto"
            src={process.env.PUBLIC_URL + "img/a2y-group-logo_vert.png"}
            alt="App to you"
          />
        </div>
      </div>
    </nav>
  )
}

export default Navbar